<template>
  <main>
    <div v-if="investment && offering">
      <div v-if="investment.status != 'draft'">
        <div class="form-outline mb-4 container-fluid d-flex justify-content-center">
          Your investment, <b>{{ investment.investmentName }}</b>, has already been submitted and can no longer be edited.
        </div>
        <div class=" form-outline mb-4 container-fluid">
          <div class="row text-center p-5">
            <div class="col-md-6">
              <router-link :to="{ name: 'Investments', params: { id: offering.id } }" class="btn btn-primary">
                View submitted investments
              </router-link>
            </div>
            <div class="col-md-6">
              <router-link :to="{
                  name: 'OfferingInvest',
                  params: {
                    id: offering.id
                  }
                }" class="btn btn-primary" style="position: absolute, left: 0">create a new investment</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <form @submit.prevent="handleSubmit">
          <h6>Investment Details</h6>
          <div class="form-outline mb-4 container-fluid">
            <div class="form-outline mb-4">
              <label v-if="investment.investmentType == 'individual'">Are you an accredited investor?</label>
              <label v-if="investment.investmentType == 'joint'">Are you and your spouse accredited investors?</label>
              <label v-if="investment.investmentType == 'entity'">Is this entity accredited?</label>
              <div class="pt-2">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="investorType" id="accredited"
                    v-model="investment.primaryAccreditedInvestor" value="yes" @change="handleAccreditationChange"
                    required />
                  <label class="form-check-label" for="offeringEquity">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="investorType" id="non-accredited"
                    v-model="investment.primaryAccreditedInvestor" value="no" @change="handleInvestmentBlur" required />
                  <label class="form-check-label" for="offeringDebt">No</label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="investment.primaryAccreditedInvestor == 'no'" class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-6">
                <label v-if="investment.investmentType == 'individual'">Annual Income</label>
                <label v-if="investment.investmentType == 'joint'">Joint Annual Income</label>
                <label v-if="investment.investmentType == 'entity'">Entity Annual Income</label>
                <input type="number" min="0" class="form-control" v-model="investment.primaryAnnualIncome"
                  @blur="handleInvestmentBlur" />
              </div>
              <div class="col">
                <div v-if="investment.investmentType == 'entity'">
                  <label>Entity Total Assets</label>
                </div>
                <div v-else>
                  <label>Household Net Worth (<a
                      href="https://www.sec.gov/oiea/investor-alerts-bulletins/ib_crowdfunding-.html" target="_blank">how
                      do I calculate?</a>)</label>
                </div>
                <input type="number" min="0" class="form-control" v-model="investment.primaryNetWorth"
                  @blur="handleInvestmentBlur" />
              </div>
            </div>
          </div>
          <div v-if="investment.primaryAccreditedInvestor == 'no'" class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-6">
                <div class="form-outline mb-4">
                  <label v-if="investment.investmentType == 'individual'">Have you invested in any other Regulation
                    Crowdfunding
                    Offerings?
                  </label>
                  <label v-if="investment.investmentType == 'joint'">Have you or your spouse invested in any other
                    Regulation
                    Crowdfunding Offerings?
                  </label>
                  <label v-if="investment.investmentType == 'entity'">Has this entity invested in any other Regulation
                    Crowdfunding Offerings?
                  </label>
                  <div class="pt-2">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="otherInvestments" id="yesOtherInvestments"
                        v-model="investment.primaryOtherInvestments" value="yes" required />
                      <label class="form-check-label" for="offeringEquity">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="otherInvestments" id="noOtherInvestments"
                        v-model="investment.primaryOtherInvestments" value="no" @change="handleOtherInvestmentChange"
                        required />
                      <label class="form-check-label" for="offeringDebt">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="investment.primaryOtherInvestments == 'yes'" class="col-md-6">
                <label>Total amount in USD invested in Regulation Crowdfunding
                  offerings in past 12 months:
                </label>
                <input type="text" class="form-control" v-model="investment.primaryOtherInvestmentTotal"
                  @blur="handleInvestmentBlur" />
                <div v-if="errorOtherInvestments" class="error">
                  {{ errorOtherInvestments }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div v-if="investment.primaryAccreditedInvestor == 'no'" class="col-md-3">
                <p>
                  Your Investment Limit is
                  <b>${{ numberWithCommas(finraUserInvestmentLimit) }}</b>
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  The Offering Minimum Investment is
                  <b>${{ numberWithCommas(offering.minInvestment) }}</b>
                </p>
              </div>
              <div class="col-md-5">
                The Offering Maximum Investment is
                <b>${{ numberWithCommas(offering.maxInvestment) }}</b>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <label>How much do you wish to invest in this offering? (USD)
            </label>
            <input type="number" class="form-control" v-model="investment.totalInvestment" @blur="handleInvestmentBlur" />
            <div v-if="legalInvestmentMessage" class="error">
              {{ legalInvestmentMessage }}
            </div>
          </div>
          <div class="form-outline mb-4 container-fluid">
            <div class="row">
              <div class="col-md-6">
                <label>Total Investment</label>
                <h5 v-if="wholeShareInvestmentTotal < 0">
                  ${{ numberWithCommas(investment.totalInvestment) }}
                </h5>
                <h5 v-else>
                  ${{ numberWithCommas(wholeShareInvestmentTotal) }}
                </h5>
              </div>
              <div class="col-md-6">
                <label>Total Shares (${{
                  numberWithCommas(offering.pricePerShare)
                }}/share)</label>
                <h5>{{ investment.totalShares }}</h5>
              </div>
            </div>
          </div>
          <div class="form-outline mb-4">
            <label>Payment Method?</label>
            <div class="pt-2">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="paymentMethod" id="ach"
                  v-model="investment.paymentMethod" value="ACH" required />
                <label class="form-check-label" for="achMethod">ACH Transfer</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="paymentMethod" id="wire"
                  v-model="investment.paymentMethod" value="Wire" required />
                <label class="form-check-label" for="wireMethod">Wire</label>
              </div>
            </div>
          </div>
          <div v-if="investment.paymentMethod == 'ACH'">
            <h6>ACH Account</h6>
            <div class="row form-outline mb-4">
              <div class="col-lg">
                <label>Account Holder Name</label>
                <input type="text" v-model="investment.paymentAccountHolderName" class="form-control" required />
              </div>
              <div class="col-lg">
                <label>Account Type</label>
                <div class="pt-2">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="accountType" id="checking"
                      v-model="investment.paymentAccountType" value="Checking" required />
                    <label class="form-check-label" for="checking">Checking</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="accountType" id="savings"
                      v-model="investment.paymentAccountType" value="Savings" required />
                    <label class="form-check-label" for="savings">Savings</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-outline mb-4">
              <img src="@/assets/img/CheckImage.png" class="check-image" />
            </div>
            <div class="row form-outline mb-4">
              <div class="col-lg">
                <label>Routing Number</label>
                <input type="text" v-model="investment.paymentAccountRoutingNumber" class="form-control" required />
              </div>
              <div class="col-lg">
                <label>Account Number</label>
                <input type="text" v-model="investment.paymentAccountNumber" class="form-control" required />
              </div>
              <div class="col-lg">
                <label v-if="isAccountNumberConfirmed" class="text-success">Confirm Account Number</label>
                <label v-else class="text-danger">Confirm Account Number</label>
                <input type="text" class="form-control" v-model="investment.paymentAccountNumberConfirmed" required />
              </div>
            </div>
          </div>
          <div v-if="errorOffering" class="error">{{ errorOffering }}</div>
          <div v-if="errorInvestment" class="error">{{ errorInvestment }}</div>
          <div class="row text-center">
            <div class="col">
              <router-link :to="{
                name: 'InvestorDetails',
                params: { id: offering.id, draftId: investment.id }
              }" class="btn btn-secondary">
                <i class="bi bi-arrow-left"></i>Prev
              </router-link>
            </div>
            <div v-if="isAccountNumberConfirmed &&
              investment.isLegalInvestment &&
              (!errorOtherInvestments ||
                investment.primaryAccreditedInvestor == 'yes')
              " class="col">
              <button class="btn btn-primary">
                Save<i class="bi bi-arrow-right"></i>
              </button>
            </div>
            <div v-else class="col">
              <button class="btn btn-primary" disabled>
                Save<i class="bi bi-arrow-right"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </main>
  <Footer v-if="investment" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";
import { computed, ref } from "@vue/runtime-core";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import usePrettyNumbers from "@/composables/usePrettyNumbers";

export default {
  props: ["id", "draftId"],
  components: { Footer },
  setup(props) {
    const router = useRouter();
    const legalInvestmentMessage = ref(null);
    const wholeShareInvestmentTotal = ref(-1);
    const { numberWithCommas, toNumber } = usePrettyNumbers();

    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.id
    );
    const { error: errorInvestment, document: investment } = getDocument(
      "investmentDrafts",
      props.draftId
    );

    const { error: errorUpdate, updateDoc: updateInvestment } = useDocument(
      "investmentDrafts",
      props.draftId
    );
    // const toNumber = plainNumber => {
    //   var n = Number(plainNumber);
    //   if (isNaN(n)) {
    //     return 0;
    //   } else {
    //     return n;
    //   }
    // };

    const prior12MonthMax = currentMax => {
      var prior12MonthMax =
        currentMax - toNumber(investment.value.primaryOtherInvestmentTotal);
      return prior12MonthMax < 0 ? 0 : prior12MonthMax;
    };

    const errorOtherInvestments = computed(() => {
      var errors = null;
      var otherInvestmentTotal = toNumber(
        investment.value.primaryOtherInvestmentTotal
      );
      if (investment.value.primaryOtherInvestments == "yes") {
        if (otherInvestmentTotal <= 0) {
          errors = "Your other investments should be greater than zero.";
        }
      }
      return errors;
    });

    const finraUserInvestmentLimit = computed(() => {
      var finraIncomeNetWorthLimit = 124000;
      var finraInvestmentMinimum = 2500;
      var finraPercentOfWorthLow = 0.05;
      var finraPercentOfWorthHigh = 0.1;
      var percentGreaterWorth = 0;
      var annualIncome = toNumber(investment.value.primaryAnnualIncome);
      var netWorth = toNumber(investment.value.primaryNetWorth);
      var offeringMaxInvestment = toNumber(offering.value.maxInvestment);

      if (
        annualIncome < finraIncomeNetWorthLimit ||
        netWorth < finraIncomeNetWorthLimit
      ) {
        // investment limit is the greater of $2,200 or
        // 5% of the greater of annual income or net Worth
        // adding the $107,000 cap even though the rules doesn't read that way
        percentGreaterWorth =
          (annualIncome > netWorth ? annualIncome : netWorth) *
          finraPercentOfWorthLow;
        console.log(
          `Annual Income: ${annualIncome} Net Worth: ${netWorth} Investor Limit: ${percentGreaterWorth} Offering Limit: ${offeringMaxInvestment}`
        );
        if (finraInvestmentMinimum > percentGreaterWorth) {
          return finraInvestmentMinimum < offeringMaxInvestment
            ? prior12MonthMax(finraInvestmentMinimum)
            : prior12MonthMax(offeringMaxInvestment);
        } else {
          if (finraIncomeNetWorthLimit < percentGreaterWorth) {
            return finraIncomeNetWorthLimit < offeringMaxInvestment
              ? prior12MonthMax(finraIncomeNetWorthLimit)
              : prior12MonthMax(offeringMaxInvestment);
          } else {
            return percentGreaterWorth < offeringMaxInvestment
              ? prior12MonthMax(percentGreaterWorth)
              : prior12MonthMax(offeringMaxInvestment);
          }
        }
      } else {
        // Income and Net Worth both over FINRA limit
        // Limit 10% of greater of Income/Net Worth not to exceed $107,000
        console.log(`Income and Worth both over ${finraIncomeNetWorthLimit}`);
        percentGreaterWorth =
          (annualIncome > netWorth ? annualIncome : netWorth) *
          finraPercentOfWorthHigh;
        return finraIncomeNetWorthLimit < percentGreaterWorth
          ? prior12MonthMax(finraIncomeNetWorthLimit)
          : prior12MonthMax(percentGreaterWorth);
      }
    });

    const isAccountNumberConfirmed = computed(() => {
      return (
        investment.value.paymentMethod != "ACH" ||
        (investment.value.paymentAccountNumber.length > 4 &&
          investment.value.paymentAccountNumber ==
          investment.value.paymentAccountNumberConfirmed)
      );
    });

    const handleAccreditationChange = () => {
      investment.value.primaryAnnualIncome = 0;
      investment.value.primaryNetWorth = 0;
      handleOtherInvestmentChange();
    };

    const handleOtherInvestmentChange = () => {
      investment.value.primaryOtherInvestmentTotal = 0;
      handleInvestmentBlur();
    };

    const handleInvestmentBlur = () => {
      console.log(
        `Investment Check: Accredited ${investment.value.primaryAccreditedInvestor}`
      );
      var totalInvestment = toNumber(investment.value.totalInvestment);
      var maxInvestment = toNumber(offering.value.maxInvestment);
      var minInvestment = toNumber(offering.value.minInvestment);
      var pricePerShare = toNumber(offering.value.pricePerShare);
      wholeShareInvestmentTotal.value = 0;
      investment.value.isLegalInvestment = false;
      investment.value.totalShares = 0;

      legalInvestmentMessage.value = null;
      if (
        (investment.value.primaryAccreditedInvestor == "yes" ||
          totalInvestment <= finraUserInvestmentLimit.value) &&
        totalInvestment <= maxInvestment &&
        totalInvestment >= minInvestment
      ) {
        if (investment.value && offering.value) {
          if (offering.value.pricePerShare > 0) {
            investment.value.totalShares = Math.floor(
              totalInvestment / pricePerShare
            );
            var remainder = totalInvestment % pricePerShare;
            if (remainder > 0) {
              legalInvestmentMessage.value = `Investment was changed to be a whole number of shares (from: $${numberWithCommas(
                totalInvestment
              )})`;
            }
            console.log(`Remainder: ${remainder}`);
            wholeShareInvestmentTotal.value = totalInvestment - remainder;
            investment.value.totalInvestment = wholeShareInvestmentTotal.value;
            investment.value.isLegalInvestment = true;
          } else {
            investment.value.totalInvestment = totalInvestment;
            wholeShareInvestmentTotal.value = totalInvestment;
            investment.value.isLegalInvestment = true;
          }
        } else {
          legalInvestmentMessage.value =
            "This offering is not available for investment, please check back soon.";
        }
      } else {
        if (finraUserInvestmentLimit.value < minInvestment) {
          legalInvestmentMessage.value =
            "The offering's minimum exceeds your available Reg CF annual investment limit.";
        } else {
          if (minInvestment == finraUserInvestmentLimit.value) {
            legalInvestmentMessage.value = `Your investment must equal $${numberWithCommas(
              minInvestment
            )}`;
          } else {
            if (investment.value.primaryAccreditedInvestor == "yes") {
              legalInvestmentMessage.value = `Your investment must be between $${numberWithCommas(
                minInvestment
              )}  and $${numberWithCommas(maxInvestment)}`;
            } else {
              legalInvestmentMessage.value = `Your investment must be between $${numberWithCommas(
                minInvestment
              )}  and $${numberWithCommas(
                finraUserInvestmentLimit.value < maxInvestment
                  ? finraUserInvestmentLimit.value
                  : maxInvestment
              )}`;
            }
          }
        }
      }
    };

    const handleSubmit = async () => {
      console.log("Save Investment");
      handleInvestmentBlur();
      if (investment.value.isLegalInvestment) {
        await updateInvestment({
          paymentAccountHolderName: investment.value.paymentAccountHolderName,
          paymentAccountType: investment.value.paymentAccountType,
          paymentAccountRoutingNumber:
            investment.value.paymentAccountRoutingNumber,
          paymentAccountNumber: investment.value.paymentAccountNumber,
          paymentAccountNumberConfirmed: investment.value
            .paymentAccountNumberConfirmed
            ? investment.value.paymentAccountNumberConfirmed
            : "",
          isLegalInvestment: investment.value.isLegalInvestment,
          primaryAccreditedInvestor: investment.value.primaryAccreditedInvestor,
          primaryAnnualIncome: toNumber(investment.value.primaryAnnualIncome),
          primaryNetWorth: toNumber(investment.value.primaryNetWorth),

          primaryOtherInvestments: investment.value.primaryOtherInvestments,
          primaryOtherInvestmentTotal: toNumber(
            investment.value.primaryOtherInvestmentTotal
          ),
          totalInvestment: toNumber(investment.value.totalInvestment),
          totalShares: toNumber(investment.value.totalShares),
          paymentMethod: investment.value.paymentMethod,
          interestRate: offering.value.interestRate,
          maturityDate: offering.value.maturityDate,
          maturityYears: offering.value.maturityYears,
          pricePerShare: offering.value.pricePerShare,
          rewards: offering.value.rewards,
          zsDocumentRequestId: "",
          zsDocumentActionId: "",
          editedAt: timestamp()
        });
        if (errorUpdate.value) {
          console.log(`Error: ${errorUpdate.value}`);
        } else {
          router.push({
            name: "InvestmentReview",
            params: { id: offering.value.id, draftId: props.draftId }
          });
        }
      }
    };

    return {
      isAccountNumberConfirmed,
      errorOffering,
      offering,
      investment,
      errorInvestment,
      errorOtherInvestments,
      finraUserInvestmentLimit,
      wholeShareInvestmentTotal,
      legalInvestmentMessage,
      handleInvestmentBlur,
      handleOtherInvestmentChange,
      handleAccreditationChange,
      handleSubmit,
      numberWithCommas
    };
  }
};
</script>

<style>
.check-image {
  max-width: 400px;
  overflow: hidden;
}
</style>